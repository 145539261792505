:root{
  --form-control-color: rebeccapurple;
  --talk_bg_color:#1a1a1a;
  --talk_mob_bg_color:#5CB7F0;
  --chat_bg_color:#2b2b2a;
  --chat_mob_bg_color:#FD86D4;
  --report_bg_color:#525151;
  --report_mob_bg_color:#5DC23F;
  --stone_bg_color:#6d6c6c;
  --stone_mob_bg_color:#FFBF1F;
  --our_astrologer_color:#F1A13A;
  --consult_astrologer_color:#111110;
  --offline_color:red;
  --head_color:#C49300;
  --btn_color:linear-gradient(90deg, #B68508 -9.03%, #C2900F 19.75%, #EEC746 49.14%, #FBD65F 64.58%, #FBD760 80.56%, #DAA315 95.33%, #BC8802 104.69%, #D4AF37 108.49%);
  --consultBtn_color:linear-gradient(90deg, #B68508 -9.03%, #C2900F 19.75%, #EEC746 49.14%, #FBD65F 64.58%, #FBD760 80.56%, #DAA315 95.33%, #BC8802 104.69%, #D4AF37 108.49%);
 --text_white_color:#fff; 
  --border_color:#dee2e6;
  --gradient_color:linear-gradient(80deg, #111110, #111110);
}
.pd-10 {
  padding: 10px;
}
.wd-100{
  width: 100% !important;
}
.astrologer_list_container {
  width: 75%;
}
.astrologer_list_inner_container {
  width: 100%;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}

.astrologer_list_card {
  border: 1px solid #eee;
  width: 32%;
  background-color: #fff;
  /* margin-bottom: 10px; */
  border-radius: 5px;
  /* margin-right: 10px; */
  position: relative;
  min-height: 180px;
}
.astrologer_list_img {
  width: 30%;
}

.astrologer_heading {
  text-align: left;
}
.astrologer_heading h6 {
  font-size: 14px !important;
}
.astrologer_list_card:hover {
  box-shadow: 0px 0px 10px 2px #eee;
  /* scale: 1.1; */
}
.astrologer_avater {
  width: 100%;
  position: relative;
}
.astrologer_avater .astrologer_avater_wrapper {
  width: 80px;
  height: 80px;
  /* border: 1px solid #eee; */
  /* border-radius: 50%; */
  overflow: hidden;
  cursor: pointer;
  background-image: url('../images/astro2-0/astrologer_border.png');
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 0px 10px 8px;
  display: flex;
  align-items: center;
}
.astrologer_avater .astrologer_avater_wrapper img{
  border-radius: 50%;
  width: 65px;
  height: 65px;
}
.form-check{
  border-bottom: 1px solid var(--border_color);
  line-height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form-check label {
  margin-top: 8px;
  margin-left: 8px;
}

.form-check input[type=checkbox], input[type=radio]{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}
.form-check:last-child{
  border: none;
}
.astrologer_personal_details {
  cursor: pointer;
  width: 50%;
}
.notification_btn {
  width: 10%;
}
.astrologer_desc .desc {
  margin-top: 0px;
  color: #9a9a9a;
  text-align: left;
  clear: both;
  line-height: 16px;
  font-size: 11px;
  margin-bottom: 2px;
  font-family: SF-Regular;
}
.notification_btn .bell_img {
  cursor: pointer;
}
.astrologer_list_action_group {
  padding: 0px 10px;
}
.astrologer_rate_card p {
  margin: 0px;
  font-size: 12px;
}
.Action_btn_group{
  width: 48%;

}
.Action_btn_group .call_btn {
  border-radius: 13px;
  color: var(--consult_astrologer_color);
  border: 1px solid var(--report_mob_bg_color);
  font-size: 12px;
  padding: 5px 10px;
  font-family: SF-Semibold;
  width: 100% !important;
}
.Action_btn_group .busy_btn {
  font-family: SF-Regular;
  background-color: transparent;
  border: none;
  color: #ff9c05;
  font-size: 8px;
  width: 100%;
}
.verified_tik {
  position: absolute;
  bottom: 0px;
  margin-right: -50%;
  transform: translate(-50%, 40%);
  width: 18px;
}

.Action_btn_group button {
  display: block;
  width: 100%;
}

/* === Freesession page css === */

.freesession_astrologer_list_contailer {
  width: 75%;
}
.No_astrologer_found {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.freesession_card {
  border: 1px solid #eee;
  /* width: 32.5% !important; */
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  .astrologer_list_container,
  .freesession_astrologer_list_contailer {
    width: 100%;
    padding: 15px;
  }
  .astrologer_list_card,
  .freesession_card {
    width: 100% !important;
    box-shadow: 0px 0px 10px 2px #eee;
  }
}
