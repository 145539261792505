.popup .modal-content {
  width: 402px;
  height: 231px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
}
.staticBackdrop1 .hr {
  border-bottom: 1px solid var(--border_color) !important;
}

.popup img {
  margin-right: 14.36px;
}

.popup .modal-header {
  border: 0px;
  /* border-bottom: none !important; */
}
.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}
.modal-body {
  margin-top:20px;
  text-align: center;
}
.popup22 .modal-header{
  padding: 0px;
}
 .popup22 .modal-body{
  margin-top:0px;
  padding: 0px;
}
button.No_btn{
  color: var(--text_white_color) !important;
  background-color: var(--talk_bg_color) !important;
  padding: 5px 20px;
}
.popup .modal-footer {
  border: 0px;
  border-top: none !important;
}

.popup .btn-cancel {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

.popup .btn-recharge {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

/* ---------------------------popup1--------------------- */

.popup1 .modal-content {
  width: 402px;
  height: 187px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
}
.Spinpopup .modal-content {
  width: 1000px;
  height: auto;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
}
.popup1 img {
  margin-right: 14.36px;
}

.popup1 .modal-header {
  border: 0px;
  /* border-bottom: none !important; */
}

.popup1 .modal-footer {
  border: 0px;
  border-top: none !important;
}

.popup1 .btn-cancel {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

.popup1 .btn-recharge {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

/* ---------------------------popup2--------------------- */

.popup2 .modal-content {
  width: 402px;
  height: 231px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
}

.popup2 img {
  margin-right: 14.36px;
}

.popup2 .modal-header {
  border: 0px;
  /* border-bottom: none !important; */
}

.popup2 .modal-footer {
  border: 0px;
  border-top: none !important;
}

.popup2 .btn-cancel {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

.popup2 .btn-recharge {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

.popup2 .modal-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #3c3c46;
}

/* ---------------------------Language--------------------- */

.language .modal-content {
  /* width: 651px; */
  width: auto;
  height: 377px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
}

.language img {
  width: 24px;
  margin-right: 14.36px;
  height: 24px;
}

.language .modal-header {
  height: 58px;
  background: var(--gradient_color) 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  color: #ffffff;
}

.language .modal-dialog {
  max-width: 651px;
}

.language .modal-footer {
  border: 0px;
  border-top: none !important;
}
.btn-same {
  font-size: 16px !important;
  color: #174ac0 !important;
  background-color: #ffffff !important;
  border: none !important;
  font-family: SF-Regular !important;
}
.language .btn-cancel {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

.language .btn-recharge {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #174ac0;
  text-transform: uppercase;
  opacity: 1;
}

.language .modal-body .btn {
  width: 29%;
  height: 34px;
  background: #f4f6f9 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 7px 7.5px 7px 7.5px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #414853;
  opacity: 1;
  padding: 5px;
}

.language .modal-body .active {
  background: #FF9C05 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #FF9C05;
  opacity: 1;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
}

.language .modal-body {
  padding: 4px;
}

.language .btn-done {
  border-radius: 22px;
  width: 133px;
  height: 44px;
  background: var(--btn_color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-top: -15px;
}

.language .modal-footer {
  display: block;
  text-align: center;
}
