@font-face {
  font-family: SF-Semibold;
  src: url(FontsFree-Net-SFProDisplay-Semibold.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SF-Regular;
  src: url(FontsFree-Net-SFProDisplay-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}
:root{
  --consult_astrologer_color:#111110;
  --head_color:#C49300;
  --btn_color:linear-gradient(90deg, #B68508 -9.03%, #C2900F 19.75%, #EEC746 49.14%, #FBD65F 64.58%, #FBD760 80.56%, #DAA315 95.33%, #BC8802 104.69%, #D4AF37 108.49%);
  --consultBtn_color:linear-gradient(90deg, #B68508 -9.03%, #C2900F 19.75%, #EEC746 49.14%, #FBD65F 64.58%, #FBD760 80.56%, #DAA315 95.33%, #BC8802 104.69%, #D4AF37 108.49%);
 
}
* {
  font-family: SF-Regular;
}
.sideBarIcon {
  display: none !important;
}
.container {
  max-width: 1360px;
}

.chat-oo {
  width: calc(100% - 243px);
  margin-left: 10px;
}

.header {
  min-height: 106px;
  padding: 0px 0px 0px 0px;
}

.astrology {
  color: #414853;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

/* .header-menu {
  min-height: 56px;
  background-color: #FF9C05;
  text-align: left;
} */

.page-body {
  /* margin-bottom: 320px;
  text-align: left;
  font: normal normal 600 12px/14px SF Pro Display;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1; */
}

.page-footer {
  /* position: fixed;
  bottom: 0; */
  background: var(--gradient_color);
  /* background: linear-gradient(140deg ,var(--consult_astrologer_color), #ff9c05); */
  /* background: #ff9c05; */
  /* border: 0.5px solid #ff9c05; */
  opacity: 1;
  min-height: 276px;
  padding: 24px 0px 27px 0px;
  /* margin-bottom: 77px; */
}

.header-menu .col {
  padding: 12px;
}

.contect-name {
  text-align: left;
  font: normal normal normal 15px/18px SF Pro Display;
  letter-spacing: 0px;
  color: #4e4e4e;
  opacity: 1;
  margin: 5px 0px 0px 0px;
  font-size: 15px;
}

.contect-number {
  font-size: 12px;
  text-align: left;
  font: normal normal normal 12px/14px SF Pro Display;
  letter-spacing: 0px;
  color: #4e4e4e;
  opacity: 0.6;
  margin: 0px;
}

.app-store {
  height: 40px;
  cursor: pointer;
  /* width: 150px; */
}

.footer-social-link {
  margin-top: 35px;
}

.footer-social-link button {
  width: 24px;
  height: 24px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  margin: 1px;
  padding: 0px;
}

.col-wyt {
  color: #ffffff !important;
  cursor: pointer;
}

.header-icon {
  height: 39px;
  width: 38px;
  border: 0.5px solid #cccccc;
  opacity: 1;
  padding: 4px;
  text-align: center;
  cursor: pointer;
}

.icon-color {
  color: #ff9c05;
}

.header-login-btn {
  width: 130px;
  height: 40px !important;
  border: 0.5px solid var(--head_color);
  /* background: transparent linear-gradient(180deg, #ff9c05 0%, #fe5602 100%) 0%
    0% no-repeat padding-box; */
    background: var(--btn_color);
    color: var(--consult_astrologer_color) !important;
  opacity: 1;
  font: normal normal 600 13px/15px SF Pro Display;
  border-radius: 30px;
  letter-spacing: 0px;
}

.header-signup-btn {
  width: 92px;
  height: 32px !important;
  /* border: 0.5px solid #FF9C05; */
  border: 0.5px solid #ff9c05;
  opacity: 1;
  font: normal normal 600 13px/15px SF Pro Display;
  border-radius: 0px;
  letter-spacing: 0px;
}

.header-btnClr {
  /* background: #FF9C05 0% 0% no-repeat padding-box; */
  background: var(--gradient_color);
  color: #ffffff !important;
  border-color: var(--gradient_color);
  outline: none;
}

.header-btnNotClr {
  color: #ff9c05;
  /* border-radius: 5px; */
  outline: none;
}

.footer-about-astrology {
  text-align: left;
  font: normal normal 600 var(--heading_text_size_desktop30)/29px SF Pro Display;
  letter-spacing: 0px;
  color: var(--head_color);
  opacity: 1;
}

.footer-about-astrology-content {
  text-align: justify;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.Download_our_App li{
  border: 0px !important;
}
.footer-about-astrology_card ul{
  list-style: none;
  padding: 0px;
}
.footer-about-astrology_card ul li{
  color: var(--white);
  display: inline-block;
  padding: 0px 10px;
  border-right: 1px solid #eee;
  cursor: pointer;
}
.footer-about-astrology_card ul li:last-child{
  border: 0px;
}
.footer-about-astrology_card ul li:hover{
  border: 0px;
  border-radius: 30px;
  color: var(--black);
  background: var(--consultBtn_color);
}
.Download_our_App li:hover {
  background:none !important;
}
.footer-about-astrology_card{
  padding: 10px 50px;
  text-align: left;
}

.footer-col-2 {
  width: 170px;
  height: 90px;
}

.footer-col-3 {
  width: 114px;
  height: 164px;
}

.footer-link {
  text-align: left;
  font: normal normal normal 14px/37px SF Pro Display;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.footer-link a {
  color: #ffffff;
}
.CounterOuter {
  box-shadow: 0px 0px 5px 3px #eee;
}
.weekly_top_container,.youCanAslocheck_container{
  width: 100%;
  margin: 70px 0px;
}

.youCanAslocheck_card{
  background-color: var(--consult_astrologer_color);
  padding: 40px 20px;
}
.youCanAslocheck_card_details p{
  color: var(--white);
}
.weekly_top_body{
  display: flex;
  flex: 1;
  column-gap: 25px;
}
.youCanAslocheck_container, .youCanAslocheck_card_container{
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.youCanAslocheck_card_container_card{
  border-right: 1px dashed var(--head_color);
  border-bottom: 1px dashed var(--head_color);
  padding: 20px;
}
.youCanAslocheck_card_container_card:nth-child(2){
  border-right: 0px;
}
.youCanAslocheck_card_container_card:nth-child(3){
  border-bottom: 0px;
}
.youCanAslocheck_card_container_card:last-child{
  border: 0px;
}
.youCanAslocheck_card, .youCanAslocheck_card_container_card{
  width: 50%;
}
.youCanAslocheck_card_bg{
  background-color:#111521;
  padding: 0px;
  display: flex;
  align-items: center;
}
.weekly_top_card{
  width: 58%;
  text-align: justify;
  margin-top: 10px;
}
.weekly_top_card_img{
  width: 40%;
  padding: 10px;
}
.d-flex_Align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.counterOne {
  border-right: 1px solid #eee;
  padding: 20px;
  /* display:inline-block; */
}
.footer-download {
  text-align: center;
  font: normal normal 600 var(--heading_text_size_desktop18)/37px SF Pro Display;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.termsouter ol li p,
.termsouter ol li,
.termsouter p {
  font-size: 18px !important;
}
.user {
  width: 35px;
  height: 35px;
  opacity: 1;
  border-radius: 50%;
}

.user-dropdown .dropdown-menu {
  border-radius: 0px;
}

.user-dropdown .logout {
  color: #ff9c05;
}

.notification .badge {
  background: var(--btn_color);
  color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 5px;
}

.vertical-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #41485350;
  /* height: 630px; */
}

.vertical-menu a {
  color: #4e4e4e;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 15px;
  text-decoration: none;
  text-align: left;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #4caf50;
  color: white;
}

.side-menu {
  padding: 0px;
  max-width: 243px;
}

.menu-item {
  height: 65.6px;
  border-bottom: 0.5px solid #41485350;
  cursor: pointer;
}

.menu-icon {
  margin-right: 12px;
  width: 20px;
}

.page-header .col {
  height: 129px;
}

.page-header .page-name {
  /* max-width: 304px; */
  /* max-width: 370px; */
  text-align: left;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  font-size: 24px;
  color: #414853;
  padding: 50px 0 50px 0;
  max-width: 25%;
}

.page-header .page-name span {
  color: #ff9c05;
}

.page-header .page-details {
  padding: 50px 0 50px 0;
  display: flex;
  justify-content: flex-end;
  max-width: 75%;
}

.page-header .page-details select {
  width: 100%;
  font-size: 12px;
  height: 40px;
  border: 0.5px solid #9a9a9a50;
  opacity: 1;
  border-radius: 0px;
  margin: 0 0 0 5px;
}

.page-header .page-details .searchbyname {
  width: 100%;
  height: 40px;
  border: 0.5px solid #9a9a9a50;
  opacity: 1;
  border-radius: 0px;
  margin: 0 5px;
  font-size: 12px;
}

.page-header .page-details .available-bls {
  width: auto;
  background: var(--gradient_color);
  /* border: 0.5px solid #ff9c05; */
  color: #fff;
  opacity: 1;
  margin: 0 0 0 0px;
  padding: 7px;
  font-size: 14px !important;
}

.page-header .page-details .bls {
  /* width: 83px; */
  /* height: 40px; */
  /* background: var(--btn_color); */
  color: var(--consult_astrologer_color);
  font-weight: 800;
  border: 0.5px solid var(--consult_astrologer_color);
  opacity: 1;
  /* float: left; */
  margin: 0 0px 0 0;
  padding: 6px;
  font-size: 14px;

}

.page-header .page-details .rechargBtn_ {
  width: 112px;
  height: 40px;
  float: right;
  background: #418f21 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 0px;
  margin: 0 0 0 0px;
  padding: 7px;
  color: #fff;
}

.pdb25 {
  padding-bottom: 25%;
}

.clickptr {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom:1px solid #eee;
}
.clickptr:last-child{
  border-bottom:0px;
}

.show-s {
  display: none;
}

.show-l {
  display: block;
}

@media only screen and (max-width: 768px) {
  .dd-block {
    display: block !important;
  }
  .footer-link {
    text-align: center;
  }
  .m-d-none {
    display: none;
  }
  .menu-order {
    float: left;
  }
  .show-l {
    display: none;
  }
  .show-s {
    display: block;
  }
  .widd-100 {
    width: 100% !important;
  }
  .about-img {
    margin: 0px 16px 0px 0px;
    width: 99%;
  }
  .modal-body-left-s {
    width: 100% !important;
  }
  .modal-body-ryt {
    width: 100% !important;
  }
  .tnc {
    padding-bottom: 20px;
  }
  .pdd-img {
    padding: 0px 0px 0px 4px !important;
    margin-top: 10px;
  }
  .mg-tpp {
    margin-top: 0px !important;
  }
  .d-d-none {
    display: block !important;
  }
  .daily-horoscope-detail {
    width: 100% !important;
  }
  .ads {
    max-width: 100px !important;
  }
  .ads img {
    width: 100% !important;
  }
  .daily-horoscope .col:first-child {
    max-width: 100% !important;
  }
  .footer-col-2,
  .footer-about-astrology-content {
    height: auto !important;
    width: 100% !important;
    text-align: justify;
  }
  .m-p-0 {
    padding: 0;
  }
  .our-services .our-services-detail {
    width: 100% !important;
  }
  .our-services .row .col-3 {
    margin: auto auto 58px auto !important;
  }
  .carousel-menu,
  .carousel-menu .row-cols-1 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .page-header .col {
    height: auto !important;
  }
  .page-header .page-name,
  .page-header .page-details {
    padding: 15px !important;
  }
  .page-header .page-details .available-bls,
  .page-header .page-details .bls,
  .page-header .page-details .btn {
    width: auto !important;
  }
  .page-header .page-details .available-bls {
    margin: 0 !important;
  }
  /* .side-menu {
    max-width: inherit !important;
  } */
  /* .voucher-applymall {
    margin: 0 20px !important;
  } */
  .pad-left {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .contect-icon-row .col {
    margin-bottom: 20px;
  }
  .profile-pic .right-tik {
    bottom: 138px !important;
  }
  .shot-by {
    /* padding-right: 15px !important; */
    padding-left: 15px !important;
  }
  .recharge-bls {
    /* padding-right: 15px !important;
    padding-left: 15px !important; */
    /* margin-top: 15px !important; */
  }
  .page-header .page-details {
    max-width: 100%;
  }
  /* .page-header .page-name {
    max-width: 100%;
  } */
  .page-header .page-details select {
    margin: 0 !important;
  }
  /* .popup1 .modal-content {
    height: 200px !important;
  } */
  .v-apply input {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .pro-img {
    left: 29% !important;
  }
  .pro-name {
    margin-top: -60px !important;
  }
  #profilePic {
    text-align: center;
  }
  .tick-pro-img {
    left: -21% !important;
  }
  .voucher-input {
    margin-bottom: 15px;
  }
  .sidemenu-open {
    height: 40px;
    width: 40px;
    /* position: fixed;
    top: 0%;
    left: 2%; */
  }
  .astrology-news {
    height: auto !important;
    flex: 0 0 100% !important;
  }
  .newsicon {
    width: auto !important;
    flex: 0 0 100% !important;
  }
  .astrology-news-content p {
    text-align: justify !important;
  }
  .astrology-news-content p:first-child {
    text-align: center !important;
  }
  body .daily-horoscope-carousel .carousel-inner {
    padding: 0 0 !important;
  }
  .our-story-m {
    float: left;
    width: 100% !important;
  }
  .our-story-d {
    float: right;
    width: 100% !important;
  }
  .MuiInputBase-input {
    width: 78%;
  }
  .pagination-cl {
    padding: 0px !important;
    max-width: 100% !important;
    position: absolute !important;
    top: -45px !important;
    left: 0px !important;
    text-align: center !important;
  }
  .read-more-box {
    padding: 10px !important;
  }

  /* .asrto-carousel .CarouselImg2 .col-6 {
    height: auto !important;
    width: 100%;
  }
  .asrto-carousel .carousel-item {
    width: 100% !important;
    height: auto !important;
  } */

  /* .asrto-carousel .carousel-item {
    height: 172px !important;
  } */
  .asrto-carousel img {
    height: auto !important;
    width: 100% !important;
  }
}

.astrology-news {
  padding: 20px;
}

.newsicon {
  max-width: 180px;
  padding: 18px;
}

.newsicon {
  display: flex;
  align-items: center;
}

.astrology-news-content {
  padding: 18px;
}

.astrology-news-content p {
  text-align: left;
  font-size: var(--text_size_desktop16);
  letter-spacing: 0px;
  color: #414853;
  opacity: 1;
}
.astrology-news-content p:first-child {
  text-align: left;
  text-transform: uppercase;
  font-size: var(--heading_text_size_desktop18);
  font-weight: 600;
  letter-spacing: 0px;
  color: #3c3c46;
  opacity: 1;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
}

.shot-by {
  padding-right: 0;
  padding-left: 0;
  display: flex;
}

.recharge-bls {
  padding-right: 0;
  padding-left: 0;
}

.page-header,
.page-body {
  max-width: 1200px;
  margin: 0px auto;
}

.d-d-none {
  display: none;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  /* background-color: #111; */
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background: #ff9c05 0% 0% no-repeat padding-box;
}

/* .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  } */

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media (min-width: 730px) and (max-width: 985px) {
  .border-t {
    width: 48% !important;
  }
  .p-d-e {
    padding: 0px 0px 0px 10px !important;
  }
}

@media screen and (max-width: 730px) {
  .border-t {
    width: 32.4% !important;
  }
  .p-d-e {
    padding: 0px 0px 0px 0px !important;
  }
}

@media (min-width: 670px) and (max-width: 730px) {
  .border-t {
    width: 30% !important;
  }
  .p-d-e {
    padding: 0px 0px 0px 0px !important;
  }
}

@media screen and (max-width: 670px) {
  .border-t {
    width: 100% !important;
  }
  .p-d-e {
    padding: 0px 0px 0px 0px !important;
  }
}

.m-6 {
  margin-left: 4.5% !important;
}

.m-8 {
  margin-left: 8% !important;
}

@media screen and (max-width: 767px) {
  .user-div {
    width: fit-content;
  }
  .wdd {
    width: 76% !important;
  }
  .user-img {
    float: left;
  }
  .border-t {
    height: 181px !important;
  }
  .tll {
    text-align: right !important;
  }
  .chat-oo {
    margin-top: 50px !important;
  }
  .chat-up {
    padding: 0px !important;
    margin-left: 0px !important;
  }
  .pagination-cl {
    /* position: absolute !important;
      top: -36px !important;
      left: 0 !important; */
    text-align: center;
    margin: 0px auto;
  }
  .dd-hh {
    padding: 0px 12% !important;
    margin: 0px auto !important;
  }
  .tt-ll-ee {
    text-align: left !important;
  }
  .pp-ll-ee {
    padding-left: 0px !important;
  }
  .p {
    padding: 0px !important;
  }
  .log-in {
    padding: 0px 20px !important;
  }
  .fil {
    padding: 0px 20px !important;
    width: 100% !important;
  }
  .fil-btn-otp {
    float: none !important;
  }
  .fill-up {
    text-align: center;
  }
  .gggg {
    width: fit-content !important;
    text-align: center !important;
    margin: 0px auto !important;
    float: none !important;
  }
  .enter-6 {
    text-align: left !important;
    width: 100% !important;
    padding: 0px 10px;
  }
  .input-input p {
    width: 100% !important;
    clear: both !important;
  }
  .input-input p input {
    width: 45% !important;
  }
  .input-input {
    text-align: left !important;
    margin-left: 20px !important;
  }
  .modal-body-left-ll {
    width: 100% !important;
  }
  .pandit-img-1 {
    top: 0px !important;
    margin-bottom: 20px;
  }
  .astro-head-s-1 {
    margin-top: 28px !important;
    font-family: SF-Semibold;
    font-size: 40px;
    color: #ffffff;
    margin-bottom: 16px;
    text-align: center;
  }
  .astro-dtls-s-1 {
    padding: 0px 30px;
    font-size: 16px;
    font-family: SF-Regular;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .bottom-img-1 {
    display: none;
  }
}
.astro-head-s-1 {
  margin-top: 28px !important;
  font-family: SF-Semibold;
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
}
.astro-dtls-s-1 {
  padding: 0px 30px;
  font-size: 16px;
  font-family: SF-Regular;
  color: #ffffff;
  margin-bottom: 20px;
}
.bottom-img-1 {
  display: none;
}

.otp-text-p .form-control {
  padding: 0;
  /* box-shadow: 0px 0px 10px 2px #eee; */
  height: 50px;
  /* border-radius: 10px !important; */
}
.mt45 {
  margin-top: 45px;
}

@media screen and (max-width: 480px) {
  .premiumSection {
    margin: 0px !important;
    background: #fff;
    position: fixed;
    bottom: 0;
    box-shadow: 0px 0px 5px 2px #eee;
    z-index: 11;
    padding: 10px;
    width: 100%;
    left: 0;
  }
  header .container {
    padding: 0px;
  }
  div.form-input-login input {
    margin-bottom: 0px !important;
  }
  div.form-input-login span,
  div.form-input-login span.lookicon {
    padding: 10px 13px !important;
  }
  div.form-input-login {
    padding: 0px !important;
  }
  p.log-in {
    margin: 15px !important;
    width: 100% !important;
  }
  .Our_Astrologer {
    width: 70% !important;
  }
  .feedback-div {
    width: 100% !important;
    margin-bottom: 100px !important;
  }
  .bottom-send-chat input {
    width: 75% !important;
    padding: 10px 44px 10px 23px;
    border: 0px !important;
  }
  .bottom-send-chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 0px;
    margin-bottom: 5px;
  }
  button.msg_send_btn {
    border-radius: 5px !important;
  }
}

/* Arvind add Style  */

/* astrologer profile Page */

@media screen and (max-width: 480px) {
  .page-header .page-name {
    text-align: center;
  }
  .footer-about-astrology {
    text-align: center;
  }
  .mb-480 {
    display: none !important;
  }
  .footer_bottom_line {
    font-size: 12px !important;
  }
}
